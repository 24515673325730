<template>
  <div v-if="metadata != null && showCrud">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
      :defaultImport="false"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers, useTransactions } from '@cargill/shared'
import { CrudAction } from '@brain/core'
import service from '../api/takeUpHviService'
import takeUpHviInputService from '../api/takeUpHviInputService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {},
      reloadData: false,
      showCrud: true
    }
  },
  methods: {
    async getTakeUpHviInputMeta() {
      const takeUpHviInputMeta =
        await takeUpHviInputService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpHviInputMeta,
        'takeUpHviInput',
        'bookId',
        'book',
        takeUpHviInputService
      )
      return takeUpHviInputMeta
    },
    async getMetaDetails() {
      return await Promise.all([this.getTakeUpHviInputMeta()])
    },
    canDeleteHvi() {
      const { canDelete } = useTransactions()
      return canDelete('takeUpHviInput')
    },
    async confirmDeleteHviRejectedLots() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpHvi.messages.confirmationToDeleteRejectedLots'
        )
      })
    },
    async confirmDeleteHviByBook() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpHvi.messages.confirmationToDeleteAllInputHvi'
        )
      })
    },
    async confirmDeleteHviByBookCompleted(bookId) {
      let [confirm, hasLotRejected] = await Promise.all([
        this.confirmDeleteHviByBook(),
        takeUpHviInputService.hasLotWithRejectedHviPremiumDiscountByBook(bookId)
      ])
      if (confirm) {
        if (hasLotRejected) {
          await new Promise((x) => setTimeout(x))
          confirm = await this.confirmDeleteHviRejectedLots()
        }
      }
      return confirm
    },
    async deleteHviHandler(node) {
      const bookId = node.data.id
      let confirm = await this.confirmDeleteHviByBookCompleted(bookId)
      if (confirm) {
        await takeUpHviInputService.deleteByBook(bookId)
        this.reloadData = true
      }
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      meta.id = 'takeUpHvi'
      if (this.canDeleteHvi()) {
        meta.actions = [
          {
            title: this.$t(
              'controlTower.pages.takeUpHvi.messages.deleteAllInputHvi'
            ),
            className: 'far fa-trash',
            callback: ({ node }) => {
              this.deleteHviHandler(node)
            }
          }
        ]
      }
      return meta
    },
    reload() {
      this.reloadData = true
    },
    registerCrudProcessing() {
      takeUpHviInputService.registerPostProcessing(
        CrudAction.DELETE,
        this.reload
      )
    },
    cancelCrudProcessing() {
      takeUpHviInputService.cancelPostProcessing(CrudAction.DELETE, this.reload)
    }
  },
  beforeDestroy() {
    this.cancelCrudProcessing()
  },
  created() {
    this.registerCrudProcessing()
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
