import {
  api,
  createCrudService,
  responseToOptionsInContext,
  handleError,
  i18n,
  notify
} from '@cargill/shared'
import _ from 'lodash'

const baseUrl = '/api/controltower/takeUpHviInput'
const service = createCrudService(baseUrl, api, {
  importLogUrl: `${baseUrl}/importLog`,
  masterName: 'bookId'
})

service.deleteByBook = async (bookId) => {
  try {
    const response = await api.delete(`${baseUrl}/deleteByBook/${bookId}`)
    notify.success({
      title: i18n.t('controlTower.pages.stuffingPortal.deleteSuccess')
    })
    return response
  } catch (e) {
    handleError(e)
  }
}

service.hasLotWithRejectedHviPremiumDiscountByBook = async (bookId) => {
  try {
    const response = await api.get(
      `${baseUrl}/hasLotWithRejectedHviPremiumDiscountByBook/${bookId}`
    )
    return response.data
  } catch (e) {
    handleError(e)
  }
}

service.hasLotWithRejectedHviPremiumDiscountByFilter = async () => {
  try {
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `?${service.lastAppliedFilter}`
    const response = await api.get(
      `${baseUrl}/hasLotWithRejectedHviPremiumDiscountByFilter${filter}`
    )
    return response.data
  } catch (e) {
    handleError(e)
  }
}

service.hasLotWithRejectedHviPremiumDiscount = async (ids) => {
  try {
    const response = await api.post(
      `${baseUrl}/hasLotWithRejectedHviPremiumDiscount`,
      ids
    )
    return response.data
  } catch (e) {
    handleError(e)
  }
}

// eslint-disable-next-line no-unused-vars
service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
}

export default service
